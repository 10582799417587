


//AHW Sticky header
$(function () {
  var e = $(document).scrollTop();
  var t = $(".navbar").outerHeight();
  $(window).scroll(function () {
    var n = $(document).scrollTop();
    if ($(document).scrollTop() >= 0) {
      $(".navbar").css("position", "relative");
      $(".full-width-board").css("display", "block");
      $(".top-header").removeClass("top-header-visible");
    }
    if ($(document).scrollTop() >= 10) {
      $(".navbar").css("position", "fixed");
      $(".full-width-board").css("display", "none");
      $(".top-header").addClass("top-header-visible");
    }
  });
});

if($(window).width() > 1024) {
  
  //AHW Sticky Sidebar
  $("#sticky-sidebar").stick_in_parent({
    offset_top: 20,
  });
  $("#sticky-sidebar-2").stick_in_parent({
    offset_top: 30,
  });
  $("#sticky-sidebar-3").stick_in_parent({
    offset_top: 90,
  });

}


//AHW Search Box header

$(document).on('click', '.prevent_a', function (e) {
  e.preventDefault();
});

$('.search-icon').on('click', function (e) {
  e.stopPropagation();
  $('.search-wrapper').toggleClass('active');
  $('.eco-system-dropdown').removeClass('active');
  $('.user-dropdown').removeClass('active');
});

//AHW Ecco Menu header

$('.menu-icon').on('click', function (e) {
	e.stopPropagation();
	$('.eco-system-dropdown').toggleClass('active');
  $('.user-dropdown').removeClass('active');
  $('.search-wrapper').removeClass('active');
});

//AHW Notification Menu header

$('.notification-icon, .notification-icon+.count').on('click', function (e) {
  e.stopPropagation();
  $('.notification-menu').toggleClass('active');
});

//AHW User Menu dropdown

$('.my-account').on('click', function (e) {
  e.stopPropagation();
  $('.user-dropdown').toggleClass('active');
  $('.eco-system-dropdown').removeClass('active');
  $('.search-wrapper').removeClass('active');
});

//AHW Mobile Menu

$('.nav-icon').on('click', function () {
  $('.mobile-menu').toggleClass('mobile-menu-active')
  $(".overlay").toggleClass("overlay-menu");
})

//AHW Close Mobile Menu

$('.close-menu').click(function () {
  $('.mobile-menu').removeClass('mobile-menu-active');
  $(".overlay").toggleClass("overlay-menu");
})

//AHW Close all Menus when click anywhere

$('html, body').on("click", function(e) {
  $('.user-dropdown').removeClass('active');
  $('.eco-system-dropdown').removeClass('active');
})

//AHW dark mode switch

var checkbox = document.getElementById("toggle-switch"); //get the checkbox to a variable

//check storage if dark mode was on or off
if (sessionStorage.getItem("mode") == "dark") {
  darkmode(); //if dark mode was on, run this funtion
} else {
  nodark(); //else run this funtion
}

//if the checkbox state is changed, run a funtion
checkbox.addEventListener("change", function () {
  //check if the checkbox is checked or not
  if (checkbox.checked) {
    darkmode(); //if the checkbox is checked, run this funtion
  } else {
    nodark(); //else run this funtion
  }
});

//function for checkbox when checkbox is checked
function darkmode() {
  document.body.classList.add("dark-mode"); //add a class to the body tag
  checkbox.checked = true; //set checkbox to be checked state
  sessionStorage.setItem("mode", "dark"); //store a name & value to know that dark mode is on
}

//function for checkbox when checkbox is not checked
function nodark() {
  document.body.classList.remove("dark-mode"); //remove added class from body tag
  checkbox.checked = false; //set checkbox to be unchecked state
  sessionStorage.setItem("mode", "light"); //store a name & value to know that dark mode is off or light mode is on
}



//AHW lazyload images
if ('loading' in HTMLImageElement.prototype) {
  const images = document.querySelectorAll('img[loading="lazy"]');
  images.forEach(img => {
    img.src = img.dataset.src;
  });
} else {
  // Dynamically import the LazySizes library
  const script = document.createElement('script');
  script.src = 'https://cdnjs.cloudflare.com/ajax/libs/lazysizes/5.1.2/lazysizes.min.js';
  document.body.appendChild(script);
}

$('.icon-password').on('click', function (e) {
  e.preventDefault();
  $(this).toggleClass('show-password');
  if($(this).hasClass('show-password')) {
    $(this).removeClass('hidden-password');
    $(this).find('.hidden').removeClass('d-none').addClass('d-block');
    $(this).find('.show').removeClass('d-block').addClass('d-none');
    $(this).siblings('input').attr('type', 'text');
  } else {
    $(this).addClass('hidden-password');
    $(this).find('.show').removeClass('d-none').addClass('d-block');
    $(this).find('.hidden').removeClass('d-block').addClass('d-none');
    $(this).siblings('input').attr('type', 'password');
  }
});